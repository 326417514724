
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { AnalysisResults } from '@models/analysis/analysis-results.model';

export const loadAnalysiss = createAction(
  '[Analysis] Load Analysiss'
);

export const loadAnalysisById = createAction(
  '[Analyis] API - laoad analysis by id form frirebase',
  props<{ id: string }>()
);

export const loadAnalysissSuccess = createAction(
  '[Analysis] Load Analysiss Success',
  props<{ data: any }>()
);

export const loadAnalysissFailure = createAction(
  '[Analysis] Load Analysiss Failure',
  props<{ error: any }>()
);

export const addSingleAnalysis = createAction(
  '[Analyis] Reducer - Add single analysis to store',
  props<{ analysis: AnalysisResults }>()
);

export const activeStride = createAction(
  '[Analysis] Reducer - set active stride',
  props<{ id: any }>()
);

export const updateSingleAnalysis = createAction(
  '[Analysis] Reducer - Update single anlysis',
  props<{ update: Update<any> }>()
)
