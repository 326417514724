import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, USE_EMULATOR, REGION } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthEffects } from '@store/auth-store/effects/auth.effects';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { SharedModule } from './shared/shared.module';
import { metaReducers, reducers } from '@store/index';
import { AnalysisEffects } from '@store/analysis-store/effects/analysis.effects';
import { HorsesEffects } from '@store/horses-store/effects/horses.effects';
import { UserEffects } from '@store/user-store/effects/user.effects';
import { CustomRouterStateSerializer } from '@store/router-store/custom-route-serializer';
import { RootStoreModule } from '@store/root-store.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, //.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    RootStoreModule,
    EffectsModule.forRoot([AppEffects]),
    EffectsModule.forFeature([AuthEffects, HorsesEffects, AnalysisEffects, UserEffects]),
    AngularFireFunctionsModule,
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouterStateSerializer }),
    SharedModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: USE_EMULATOR, useValue: environment.hmr ? ['localhost', 5001] : undefined },
    { provide: REGION, useValue: 'europe-west1' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
