import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { StrideReasonsDetailItem } from '../../../../../models/analysis/stride-reasons.model';
import { StrideFilteredKeyPointsItem } from '../../../../../models/analysis/stride-filtered-key-points.model';

// * StrideReview Adapter *//
export const loadSingleStrideReview = createAction(
  '[StrideReview] Load Single StrideReview',
  props<{ recordingId: string; reviewDataBlob: string }>()
);

export const addSingleStrideReview = createAction('[StrideReview] Add Single StrideReview');

export const loadStrideReviewsSuccess = createAction(
  '[StrideReview] Load StrideReviews Success',
  props<{ data: any }>()
);

export const loadStrideReviewsFailure = createAction(
  '[StrideReview] Load StrideReviews Failure',
  props<{ error: any }>()
);

/** StrideFilteredKeyPointsItem Adapter */

export const addStrideFilteredKeyPointsItem = createAction(
  '[StrideFilteredKeyPointsItem] Add StrideFilteredKeyPointsItem List',
  props<{ strideFilteredKeyPoints: any }>()
);

export const updateSingleStride = createAction(
  '[StrideFilteredKeyPointsItem] Update Single Stride',
  props<{ update: Update<StrideFilteredKeyPointsItem> }>()
);

export const clearStrideFilteredKeyPointsItem = createAction(
  '[StrideFilteredKeyPointsItem] Clear StrideFilteredKeyPointsItem'
);

/** StrideReasonsDetailItem Adapter*/

export const addStrideReasonsDetailItemList = createAction(
  '[StrideReasonsDetailItem] Add StrideReasonsDetailItems List',
  props<{ strideReasonsList: any }>()
);

export const updateSingleStrideReason = createAction(
  '[StrideReasonsDetailItem] Update Single Stride',
  props<{ update: Update<StrideReasonsDetailItem> }>()
);

export const clearStrideReasonsDetailItemsList = createAction(
  '[StrideReasonsDetailItem] Clear StrideReasonsDetailItems'
);
