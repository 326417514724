import { User } from '@models/user.model';
import { createAction, props } from '@ngrx/store';

export const loadUsers = createAction('[User] Load Users');

export const loadUsersByIds = createAction('[User] Load Users by Ids', props<{ uids: any[] }>());

export const loadUsersSuccess = createAction('[User] Load Users Success', props<{ users: User[] }>());

export const loadSingleUser = createAction('[User] Load single user', props<{ uid: string }>());

export const loadUsersFailure = createAction('[User] Load Users Failure', props<{ error: any }>());

export const addUsers = createAction('[User] Add users', props<{ users: User[] }>());

export const addSingleUser = createAction('[User] Add single user', props<{ user: User }>());

export const updateUser = createAction('[User] Update user', props<{ user: any }>());

export const successUpdateUser = createAction('[User] Success Update user');

export const failedUpdateUser = createAction('[User] Failed update user', props<{ err: any }>());

export const updateUserHorsesLoadStatus = createAction(
  '[User] Update user horses load status',
  props<{ horsesLoadStatusUpdatedUser: User }>()
);
