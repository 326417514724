<ng-container *ngIf="control === 'appAccess'">
  <mat-form-field>
    <mat-select [value]="value" (selectionChange)="emitChange($event.value)">
      <mat-option value="recorder"> Rec App</mat-option>
      <mat-option value="vet">Vet App</mat-option>
      <mat-option value="EHP"> EHP</mat-option>
      <mat-option value="all">DEPRICATED PLEASE CHANGE</mat-option>
      <mat-option value="none">None</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>