import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AnalysisEffects } from './effects/analysis.effects';
import * as fromAnalysis from './reducers/analysis.reducer';
import { AnalysisResultsService } from '@store/analysis-store/services/analysis-results.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('analysis', fromAnalysis.reducer),
    EffectsModule.forFeature([AnalysisEffects]),
  ],
  providers: [AnalysisResultsService],
})
export class AnalysisStoreModule {}
