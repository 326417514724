<button class="button" [ngClass]="{
    primary: type === 'primary',
    secondary: type === 'secondary',
    warn: type === 'warn',
    error: type === 'error',
    idle: type === 'idle',
    accent: type === 'accent',
    working: type === 'working',
    large: size === 'large',
    medium: size === 'medium',
    small: size === 'small'
  }" [disabled]="disabled">
  <ng-content></ng-content>
</button>