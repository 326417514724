import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap().catch((err: any) => console.log(err));
    // function bootstrap() {
    //   bootstrap();
    // }

    // if (document.readyState === 'complete') {
    //   bootstrap();
    // } else {
    //   document.addEventListener('DOMContentLoaded', bootstrap);
    // }
  });
}
