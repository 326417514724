import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HorsesEffects } from './effects/horses.effects';
import * as fromHorses from './reducers/horses.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromHorses.horsesFeatureKey, fromHorses.reducer),
    EffectsModule.forFeature([HorsesEffects])
  ]
})
export class HorsesStoreModule { }
