import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AnalgesiaTag } from '@models/tags/analgesia-tag.model';

export const analgesiaTagsFeatureKey = 'analgesiaTags';

export interface AnalgesiaTagState extends EntityState<AnalgesiaTag> {}

export const anaglesiaTagAdapter: EntityAdapter<AnalgesiaTag> = createEntityAdapter<AnalgesiaTag>();

export const {
  selectIds: selectAnalgesisaTagIdsAdapter,
  selectEntities: selectAnalgesiaTagEntitiesAdapter,
  selectAll: selectAllAnalgesiaTagsAdapter,
  selectTotal: selectAnalgesiaTagsTotalAdapter,
} = anaglesiaTagAdapter.getSelectors();
