import { createAction, props } from '@ngrx/store';

export const loadIssueStores = createAction(
  '[IssueStore] Load IssueStores'
);

export const loadIssueStoresSuccess = createAction(
  '[IssueStore] Load IssueStores Success',
  props<{ data: any }>()
);

export const loadIssueStoresFailure = createAction(
  '[IssueStore] Load IssueStores Failure',
  props<{ error: any }>()
);
