import { createReducer, on } from '@ngrx/store';
import * as CommonActions from '../actions/common.actions';

export const commonFeatureKey = 'common';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  common: any[];
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  common: []
};


export const reducer = createReducer(
  initialState,

  on(CommonActions.loadCommons, (state) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    }
  }),
  on(CommonActions.loadCommonsSuccess, (state) => state),
  on(CommonActions.loadCommonsFailure, (state) => state),
  on(CommonActions.addCommons, (state, action) => {
    return {
      ...state,
      common: action.commons,
      loaded: true,
      loading: false,
    }
  }),

);
