import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent {
  // TODO:  this should be made a lot more general but for now to fill the purpose I'm ok with it.
  @Input() control: string;
  @Input() value: string;
  @Output() selectedValue = new EventEmitter<string>();
  constructor() { }

  emitChange($event) {
    this.selectedValue.emit($event);
  }
}
