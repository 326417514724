import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { map, takeUntil } from 'rxjs/operators';
import { FirebaseTimings } from '@models/firebaseTimings.model';
import { SubscriptionService } from '@store/common-store/services/subscription.service';
import { AutoUnsubscribe } from '@shared/decorators/auto-unsubscribe';
import { GroupClass } from '@models/group.model';

@Injectable()
@AutoUnsubscribe
export class GroupService {
  constructor(private afs: AngularFirestore, private subService: SubscriptionService) { }

  createGroup(group) {
    return this.afs.doc(`groups/${group.firestoreId}`).set(group);
  }

  updateGroup(group) {
    return this.afs
      .doc(`groups/${group.firestoreId}`)
      .update(group)
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  searchGroupWithTerm(term) {
    return this.afs
      .collection(`groups`, (ref) =>
        ref
          .orderBy('name')
          .startAt(term)
          .endAt(term + '~')
      )
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map(
            (el: any) =>
              new GroupClass({
                id: el.payload.doc.id,
                ...el.payload.doc.data(),
              })
          )
        )
      );
  }

  getPagedGroups(offset: FirebaseTimings, limit: number) {
    return this.afs
      .collection(`groups`, (ref) => {
        let query: Query = ref;
        query = query.orderBy('name', 'asc');
        if (offset) {
          query = query.startAfter(offset);
        }
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      })
      .snapshotChanges()
      .pipe(
        takeUntil(this.subService.unsubscribe$),
        map((snap: any[]) => {
          return snap.reduce((acc, cur) => {
            const id = cur.payload.doc.id;
            const data = new GroupClass({
              id: id,
              ...cur.payload.doc.data(),
            });
            return [...acc, data];
          }, []);
        })
      );
  }

  getGroup(groupId: string) {
    return this.afs
      .doc(`groups/${groupId}`)
      .snapshotChanges()
      .pipe(
        map((group: any) => {
          return new GroupClass({
            id: group.payload.id,
            ...group.payload.data(),
          });
        })
      );
  }

  getSharedHorses(groupId) {
    return this.afs
      .collection(`groups/${groupId}/sharedHorses`)
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((el: any) => ({
            id: el.payload.doc.id,
            ...el.payload.doc.data(),
          }))
        )
      );
  }

  removeUserFromGroup(groupId: string, userToRemove: any) {
    const removedUser = userToRemove ? { ...userToRemove } : {};
    return this.afs
      .doc(`groups/${groupId}`)
      .update({
        previousUsers: firebase.firestore.FieldValue.arrayUnion(removedUser),
      })
      .then(() => {
        this.afs.doc(`groups/${groupId}`).update({
          users: firebase.firestore.FieldValue.arrayRemove(removedUser),
        });
      });
  }
}
