import { Component, Input, OnInit } from '@angular/core';
import { InformationBarSeverity } from '@models/misc/frontendSpecific';

@Component({
  selector: 'app-information-bar',
  templateUrl: './information-bar.component.html',
  styleUrls: ['./information-bar.component.scss']
})
export class InformationBarComponent implements OnInit {
  @Input() severity: InformationBarSeverity;
  constructor() { }

  ngOnInit(): void {
  }

  get getIcon() {
    if (this.severity === 'warning') {
      return 'warning';
    } else if (this.severity === 'error') {
      return 'dangerous'
    } else {
      return 'check-circle'
    }
  }

}
