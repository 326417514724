import { createReducer, on } from '@ngrx/store';
import * as TagActions from '../actions/tag.actions';
import * as AnalgesiaTagActions from '../actions/analgesia-tag.actions';
import { AnalgesiaTagState, anaglesiaTagAdapter } from '@store/tag-store/reducers/analgesia-tag.entity';

export const tagFeatureKey = 'tag';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: string;
  analgesiaTags: AnalgesiaTagState;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  analgesiaTags: anaglesiaTagAdapter.getInitialState(),
};

export const reducer = createReducer(
  initialState,

  on(TagActions.loadTags, (state) => state),
  on(TagActions.loadTagsSuccess, (state) => state),
  on(TagActions.loadTagsFailure, (state) => state),

  on(AnalgesiaTagActions.loadAnalgesiaTags, (state) => ({
    ...state,
    loading: true,
  })),
  on(AnalgesiaTagActions.loadAnalgesiaTag, (state) => ({
    ...state,
    loading: true,
  })),
  on(AnalgesiaTagActions.loadAnalgesiaTagSuccess, (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    analgesiaTags: anaglesiaTagAdapter.upsertOne(action.analgesiaTag, state.analgesiaTags),
  })),
  on(AnalgesiaTagActions.loadAnalgesiaTagsSuccess, (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    analgesiaTags: anaglesiaTagAdapter.upsertMany(action.analgesiaTags, state.analgesiaTags),
  })),
  on(AnalgesiaTagActions.deleteAnalgesiaTag, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(AnalgesiaTagActions.deleteAnalgesiaTags, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(AnalgesiaTagActions.deleteAnalgesiaTagsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    analgesiaTags: anaglesiaTagAdapter.removeMany(action.analgesiaTagIds, state.analgesiaTags),
  })),
  on(AnalgesiaTagActions.clearAnalgesiaTags, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    analgesiaTags: anaglesiaTagAdapter.removeAll(state.analgesiaTags),
  }))
);
