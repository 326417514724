import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './effects/user.effects';
import * as fromUser from './reducers/user.reducer';
import { UserService } from './services/user.service';
import { UserStoreFacade } from '@store/user-store/facades/user-store.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [UserService, UserStoreFacade],
})
export class UserStoreModule {}
