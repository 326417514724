import { Horse } from '@models/horse.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as HorsesActions from '../actions/horses.actions';

export const horsesFeatureKey = 'horses';

export interface State extends EntityState<Horse> {
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

export function selectHoseId(a: Horse) {
  return a.id;
}

export function sortByName(a: Horse, b: Horse) {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<Horse> = createEntityAdapter<Horse>({
  selectId: selectHoseId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

const horseReducer = createReducer(
  initialState,
  on(HorsesActions.loadHorses, (state) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(HorsesActions.loadAdminHorses, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(HorsesActions.loadHorsesByIds, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(HorsesActions.addHorses, (state, { horses }) => {
    return adapter.upsertMany(horses, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
  on(HorsesActions.addSingleHorse, (state, { horse }) => {
    return adapter.addOne(horse, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
  on(HorsesActions.loadHorsessSuccess, (state, action) => state),
  on(HorsesActions.loadHorsessFailure, (state, action) => state)
);

// get the selectors
export const {
  selectIds: selectHorseIdsAdapter,
  selectEntities: selectHorseEntitiesAdapter,
  selectAll: selectAllHorsesAdapter,
  selectTotal: selectHorsesTotalAdapter,
} = adapter.getSelectors();

export function reducer(state: State | undefined, action: Action) {
  return horseReducer(state, action);
}
