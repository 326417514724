import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromTag from './reducers/tag.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TagEffects } from './effects/tag.effects';
import { AnalgesiaService } from '@store/tag-store/services/analgesia.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTag.tagFeatureKey, fromTag.reducer),
    EffectsModule.forFeature([TagEffects])
  ],
  providers: [AnalgesiaService],
})
export class TagStoreModule { }
