import { Action, createReducer, on } from '@ngrx/store';
import {
  strideFilteredKeyPointsItemAdapter,
  StideFilteredKeyPointsItemState,
} from './stride-filtered-key-point.entity';
import { strideReasonsDetailItemAdapter, StrideReasonsDetailItemState } from './stride-reason.entity';
import { StrideReviewActions } from '..';
import { StrideReasonsDetailItemClass } from '@models/analysis/stride-reasons.model';

export const STRIDE_REVIEW_KEY = 'strideReview';

export interface State {
  loading: boolean;
  loaded: boolean;
  filteredKeypoints: StideFilteredKeyPointsItemState;
  reasons: StrideReasonsDetailItemState;
  recordingId: string | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  filteredKeypoints: strideFilteredKeyPointsItemAdapter.getInitialState(),
  reasons: strideReasonsDetailItemAdapter.getInitialState(),
  recordingId: null,
};

export const strideReviewReducer = createReducer(
  initialState,
  on(StrideReviewActions.loadSingleStrideReview, (state, { recordingId }) => ({
    ...state,
    loading: true,
    recordingId: recordingId,
  })),
  on(StrideReviewActions.addSingleStrideReview, (state) => ({
    loading: false,
    loaded: true,
    ...state,
  })),
  on(StrideReviewActions.addStrideFilteredKeyPointsItem, (state, { strideFilteredKeyPoints }) => {
    const _strideFilteredKeyPoints = { ...strideFilteredKeyPoints };
    _strideFilteredKeyPoints.recordingId = state.recordingId;
    return {
      ...state,
      filteredKeypoints: strideFilteredKeyPointsItemAdapter.addOne(
        _strideFilteredKeyPoints,
        state.filteredKeypoints
      ),
    };
  }),
  on(StrideReviewActions.addStrideReasonsDetailItemList, (state, { strideReasonsList: reasons }) => {
    const _strideReasonsDetailItem = new StrideReasonsDetailItemClass({
      reasons: reasons,
      recordingId: state.recordingId,
    });
    return {
      ...state,
      reasons: strideReasonsDetailItemAdapter.addOne(_strideReasonsDetailItem, state.reasons),
    };
  }),

  on(StrideReviewActions.clearStrideFilteredKeyPointsItem, (state) => ({
    ...state,
    filteredKeypoints: strideFilteredKeyPointsItemAdapter.removeAll({ ...state.filteredKeypoints, ids: [] }),
  })),

  on(StrideReviewActions.clearStrideReasonsDetailItemsList, (state) => ({
    ...state,
    reasons: strideReasonsDetailItemAdapter.removeAll({ ...state.reasons, ids: [] }),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return strideReviewReducer(state, action);
}
