import { createAction, props } from '@ngrx/store';
import { AnalgesiaTag } from '@models/tags/analgesia-tag.model';

export const loadAnalgesiaTags = createAction(
  '[Tag/AnalgesiaTag] Load AnalgesiaTags',
  props<{ analgesiaTagIds: string[] }>()
);

export const loadAnalgesiaTagsSuccess = createAction(
  '[Tag/AnalgesiaTag] Upsert AnalgesiaTags',
  props<{ analgesiaTags: AnalgesiaTag[] }>()
);

export const loadAnalgesiaTag = createAction(
  '[Tag/AnalgesiaTag] Load AnalgesiaTags',
  props<{ analgesiaTagId: string }>()
);

export const loadAnalgesiaTagSuccess = createAction(
  '[Tag/AnalgesiaTag] Upsert AnalgesiaTag',
  props<{ analgesiaTag: AnalgesiaTag }>()
);

export const deleteAnalgesiaTag = createAction(
  '[Tag/AnalgesiaTag] Delete AnalgesiaTag',
  props<{ analgesiaTagId: string }>()
);

export const deleteAnalgesiaTags = createAction(
  '[Tag/AnalgesiaTag] Delete AnalgesiaTags',
  props<{ analgesiaTagIds: string[] }>()
);

export const deleteAnalgesiaTagsSuccess = createAction(
  '[Tag/AnalgesiaTag] Delete AnalgesiaTag',
  props<{ analgesiaTagIds: string[] }>()
)

export const clearAnalgesiaTags = createAction('[Tag/AnalgesiaTag] Clear AnalgesiaTags');
