import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { AnalysisResultsService } from '../services/analysis-results.service';
import * as AnalysisActions from '../actions/analysis.actions';
import { StrideReviewActions } from '@store/stride-review-store';
import { AnalysisResults } from '@models/analysis/analysis-results.model';
import { SubscriptionService } from '@store/common-store/services/subscription.service';

@Injectable()
export class AnalysisEffects {
  loadAnalysiss$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalysisActions.loadAnalysiss),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map((data) => AnalysisActions.loadAnalysissSuccess({ data })),
          catchError((error) => of(AnalysisActions.loadAnalysissFailure({ error })))
        )
      )
    );
  });

  loadSingleAnalyis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalysisActions.loadAnalysisById),
      switchMap(({ id }) => {
        return this.analysisResultsService.loadSingleAnalysisById(id).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((data) => {
            const analysis = data as AnalysisResults;
            return [
              AnalysisActions.addSingleAnalysis({ analysis: analysis }),
              StrideReviewActions.loadSingleStrideReview({
                recordingId: id,
                reviewDataBlob: analysis.reviewDataBlob,
              }),
            ];
          })
        );
      }),
      catchError((err) => {
        console.log(err);
        return EMPTY;
      })
    );
  });

  constructor(
    private actions$: Actions,
    private analysisResultsService: AnalysisResultsService,
    private subService: SubscriptionService
  ) {}
}
