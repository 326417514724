import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, concatMap, exhaustMap, catchError } from 'rxjs/operators';

import * as IssueStoreActions from '../actions/issue-store.actions';
import { IssueService } from '@store/issue-store/services/issue.service';
import { Issue } from '@models/commons/issues/issue.model';
import { of } from 'rxjs';

@Injectable()
export class IssueStoreEffects implements OnInitEffects {
  loadIssueStores$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IssueStoreActions.loadIssueStores),
      exhaustMap(() =>
        this.issueService
          .loadIssues()
          .pipe(map((issues: Issue[]) => IssueStoreActions.loadIssueStoresSuccess({ data: issues })))
      ),
      catchError((error) => of(IssueStoreActions.loadIssueStoresFailure({ error: error })))
    );
  });

  constructor(private actions$: Actions, private issueService: IssueService) {}

  ngrxOnInitEffects() {
    return IssueStoreActions.loadIssueStores();
  }
}
