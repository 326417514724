import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AnalysisResults } from '@models/analysis/analysis-results.model';
import * as AnalysisActions from '../actions/analysis.actions';
import { state } from '@angular/animations';

export const analysisFeatureKey = 'analysis';

export interface State extends EntityState<AnalysisResults> {
  loaded: boolean;
  loading: boolean;
  activeStride: number | null;
}

export function selectDataId(a: AnalysisResults) {
  return a.id;
}

export function sortById(a: AnalysisResults, b: AnalysisResults) {
  return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<AnalysisResults> = createEntityAdapter<AnalysisResults>({
  selectId: selectDataId,
  sortComparer: sortById,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  activeStride: null,
});

export const analysisReducer = createReducer(
  initialState,

  on(AnalysisActions.loadAnalysiss, (state) => state),
  on(AnalysisActions.loadAnalysissSuccess, (state) => state),
  on(AnalysisActions.loadAnalysissFailure, (state) => state),
  on(AnalysisActions.loadAnalysisById, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(AnalysisActions.addSingleAnalysis, (state, { analysis }) => {
    return adapter.upsertOne(analysis, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(AnalysisActions.activeStride, (state, { id }) => {
    return {
      ...state,
      activeStride: id,
    };
  }),
  on(AnalysisActions.updateSingleAnalysis, (state, { update }) => {
    return adapter.updateOne(update, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return analysisReducer(state, action);
}
