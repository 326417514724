import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IssueClass } from '@models/commons/issues/issue.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IssueService {
  constructor(private afs: AngularFirestore) {}

  loadIssues() : Observable<IssueClass[]>{
    return this.afs
      .collection(`/common/issues/issues/`)
      .snapshotChanges()
      .pipe(
        map((snap) => {
          return snap.map((i) => {
            const data = i.payload.doc.data() as any;
            const id = i.payload.doc.id;
            return new IssueClass({ id, ...data });
          });
        })
      );
  }
}
