import { createAction, props } from '@ngrx/store';
import { FirebaseTimings } from '@models/firebaseTimings.model';
import { Group } from '@models/group.model';

export const loadUserGroups = createAction(
  '[UserGroup] Load UserGroups',
  props<{ offset?: FirebaseTimings; limit?: number }>()
);

export const loadUserGroupsSuccess = createAction(
  '[UserGroup] Load UserGroups Success',
  props<{ data: any[] }>()
);

export const loadUserGroupsFailure = createAction(
  '[UserGroup] Load UserGroups Failure',
  props<{ error: any }>()
);

export const loadUserGroupById = createAction(
  '[UserGroup] Load UserGroupById',
  props<{ userGroupId: string }>()
);

export const loadUserGroupByIdSuccess = createAction(
  '[UserGroup] Load UserGroupById Success',
  props<{ data: any }>()
);

export const loadUserGroupByIdFailure = createAction(
  '[UserGroup] Load UserGroupById Failure',
  props<{ error: any }>()
);
export const resetUserGroupSavedSuccess = createAction('[UserGroup] Update UserGroup Success flag reset');

export const updateUserGroup = createAction('[UserGroup] Update UserGroup', props<{ userGroupForm: any }>());

export const updateUserGroupSuccess = createAction('[UserGroup] Update UserGroup Success');

export const updateUserGroupFailure = createAction(
  '[UserGroup] Update UserGroup Failure',
  props<{ error: any }>()
);

export const addNewUserGroup = createAction('[UserGroup] Add New UserGroup', props<{ userGroupForm: any }>());

export const addNewUserGroupSuccess = createAction('[UserGroup] Add New UserGroup Success');

export const addNewUserGroupFailure = createAction(
  '[UserGroup] Add New UserGroup Failure',
  props<{ error: any }>()
);

export const removeUserFromUserGroup = createAction(
  '[UserGroup] Remove User From UserGroup',
  props<{ userGroupId: string; userToRemove: any }>()
);

export const removeUserFromUserGroupSuccess = createAction('[UserGroup] Remove User From UserGroup Success');

export const removeUserFromUserGroupFailure = createAction(
  '[UserGroup] Remove User From UserGroup Failure',
  props<{ error: any }>()
);

export const updateUserGroupHorsesLoadStatus = createAction(
  '[User] Update UserGroup horses load status',
  props<{ updatedUserGroup: Group }>()
);