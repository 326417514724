import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { userGroupFeatureKey, State, selectAllUserGroupsAdapter } from '../reducers/user-group.reducer';
import { Group } from '@models/group.model';
import * as fromRouterSelector from '@store/router-store/router.selectors';

export const selectUserGroupState = createFeatureSelector<State>(userGroupFeatureKey);

export const isLoading = (state: State): boolean => state.loading;
export const isLoaded = (state: State): boolean => state.loaded;
export const recordingListOffset = (state: State): any => state.offset;
export const userGroupSaved = (state: State): boolean => state.userGroupSaved;

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserGroupState,
  isLoading
);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectUserGroupState,
  isLoaded
);
export const selectUserGroupListOffset: MemoizedSelector<object, any> = createSelector(
  selectUserGroupState,
  recordingListOffset
);

export const selectUserGroupSaved: MemoizedSelector<object, boolean> = createSelector(
  selectUserGroupState,
  userGroupSaved
);

export const selectAllUserGroups = createSelector(selectUserGroupState, selectAllUserGroupsAdapter);

export const selectPagedUserGroup = createSelector(
  selectAllUserGroups,
  selectUserGroupListOffset,
  (userGroups: Group[], offset: any) => {
    if (userGroups.length) {
      let userGroupList: Group[] = userGroups.sort((a, b) => {
        if (a.name) {
          return a.name.localeCompare(b.name);
        }
      });

      //We dont utilize the limit or offset variables at the moment since there is no pagination needed at the moment
      const limit = 10;
      if (offset) {
        const indexToStartAfter =
          userGroupList.findIndex((rec) => rec.createdAt.seconds === offset.seconds) + 1;
        const currArrayLength = userGroupList.length;
        if (currArrayLength === indexToStartAfter && currArrayLength !== 0) {
          return userGroupList;
        } else if (currArrayLength === indexToStartAfter) {
          return null;
        } else {
          return userGroupList.slice(0, indexToStartAfter + limit);
        }
      }
      return userGroupList;
    }
  }
);

export const selectSingleUserGroup = (userGroupId: string) =>
  createSelector(selectAllUserGroups, (userGroups: Group[]) => {
    if (userGroups) {
      return userGroups.find((ug) => ug.id === userGroupId) ?? null;
    } else {
      return null;
    }
  });

export const selectUserGroupHorseLoadStatusById = createSelector(
  selectAllUserGroups,
  fromRouterSelector.selectRouteNestedParam('userGroupId'),
  (userGroups: Group[], userGroupId: string) => {
    if (userGroups && userGroupId) {
      return userGroups.find((ug) => ug.id === userGroupId)?.groupHorsesLoaded ?? false;
    } else {
      return false;
    }
  }
);
