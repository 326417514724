import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { RecordingTimings } from '@models/recording/recording-timings.model';


@Pipe({
  name: 'getTime'
})
export class GetTimePipe implements PipeTransform {
  /**
   * 
   * @param timings 
   * @param args 'uploadTime', 'timeToResult', 'analysisTime'
   * @return moment.duration, 
   */
  transform(timings: RecordingTimings, args: unknown): unknown {
    const uploadStart = timings?.uploadStart ?? false;
    const uploadComplete = timings?.uploadComplete ?? false;
    const completionDate = timings?.completionDate ?? false;
    const backendRecived = timings?.backendRecived ?? false;
    const analysisCompleted = timings?.analysisCompleted ?? false;

    switch (args) {
      case 'uploadTime':
        return this.getTime(uploadStart, uploadComplete);
      case 'totalTime':
        return this.getTime(uploadStart, completionDate);
      case 'analysisCompleted':
        return this.getTime(backendRecived, analysisCompleted);
    }
  }

  getTime(start, end) {
    if (start && end) {
      return moment.duration(end.seconds - start.seconds, 'second').humanize();
    } else {
      return 0;
    }
  }
}
