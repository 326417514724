import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '@store/user-store/services/user.service';
import * as UserActions from '../actions/user.actions';
import { SubscriptionService } from '@store/common-store/services/subscription.service';

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(() => {
        return this.userService.getAllUsers().pipe(
          takeUntil(this.subService.unsubscribe$),
          map((users) => {
            return UserActions.loadUsersSuccess({ users });
          })
        );
      })
    );
  });

  /**
   * TODO: update this function to check if the user exists in store before calling for the user.
   */
  loadUsersByIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsersByIds),
      mergeMap(({ uids }) => {
        return this.userService.getUsersByIds(uids).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((users: any[]) => {
            return UserActions.addUsers({ users });
          })
        );
      })
    );
  });

  loadSingleUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadSingleUser),
      switchMap(({ uid }) => {
        const userStream = this.userService.getUser(uid);
        return userStream.pipe(
          takeUntil(this.subService.unsubscribe$),
          map((user) => {
            return UserActions.addSingleUser({ user });
          })
        );
      })
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMap(({ user }) => {
        return of(this.userService.updateUser(user));
      }),
      catchError((err) => of(UserActions.failedUpdateUser({ err }))),
      map((d) => {
        return UserActions.successUpdateUser();
      })
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private subService: SubscriptionService
  ) {}
}
