export type UserType = 'vet' | 'owner' | 'recorder';

export type AppAccessLevel = 'vet' | 'recorder' | 'user-pro' | 'all' | 'EHP';

export type Priority = 'high' | 'medium' | 'low';

export type UserStatus = 'paying' | 'recorder' | 'free' | 'internal';

export interface User {
  type: UserType;
  name: string;
  email: string;
  // Optional fields
  admin?: boolean;
  appAccess?: AppAccessLevel;
  group?: UserGroup;
  lastEvent?: string;
  lastSession?: any;
  nationality?: string;
  numHorses?: | any;
  phone?: string;
  photo?: string;
  priority?: Priority;
  shareRights?: boolean;
  longeingAccess?: boolean;
  uid?: string;
  id?: string;
  userStatus?: UserStatus;
  horsesLoadStatus?: boolean;
  testUser?: string;
  settings?: any;
  webAppAccess?: boolean;
}

export interface UserGroup {
  name: string;
  id: string;
  role: string;
}

export interface UpdateUserEmail {
  userId: string,
  newEmail: string,
}

export class UserGroupClass implements UserGroup {
  name: string;
  id: string;
  role: string;

  constructor(o: any = {}) {
    this.name = o['name'] ?? null;
    this.id = o['id'] ?? null;
    this.role = o['role'] ?? null;
  }
}

export class UserClass implements User {
  type: UserType;
  name: string;
  email: string;
  admin?: boolean;
  appAccess?: AppAccessLevel;
  group?: UserGroup;
  lastEvent?: string;
  lastSession?: any;
  nationality?: string;
  numHorses?: boolean;
  phone?: string;
  photo?: string;
  priority?: Priority;
  shareRights?: boolean;
  longeingAccess?: boolean;
  uid?: string;
  id?: string;
  userStatus?: UserStatus;
  horsesLoadStatus: boolean;
  webAppAccess?: boolean;

  constructor(o: any = {}) {
    this.type = o['type'] ?? null;
    this.name = o['name'] ?? null;
    this.email = o['email'] ?? null;
    // Optional fields
    this.admin = o['admin'] ?? false;
    this.appAccess = o['appAccess'] ?? null;
    this.group = o['group'] ? new UserGroupClass(o['group']) : null;
    this.lastEvent = o['lastEvent'] ?? null;
    this.lastSession = o['lastSession'] ?? null;
    this.nationality = o['nationality'] ?? null;
    this.numHorses = o['numHorses'] ?? false;
    this.phone = o['phone'] ?? null;
    this.photo = o['photo'] ?? null;
    this.priority = o['priority'] ?? null;
    this.shareRights = o['shareRights'] ?? false;
    this.longeingAccess = o['longeingAccess'] ?? false;
    this.uid = o['uid'] ?? null;
    this.id = o['id'] ?? null;
    this.userStatus = o['userStatus'] ?? null;
    this.horsesLoadStatus = false;
    this.webAppAccess = o['webAppAccess'] ?? false;
  }
}
