import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SubscriptionService {
  constructor() {}

  public unsubscribeNotifySubject$ = new Subject<void>();
  public unsubscribe$ = this.unsubscribeNotifySubject$.asObservable();
}
