import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { UpdateUserEmail, User, UserClass } from '@models/user.model';
import { instanceToPlain } from 'class-transformer';

interface QueryConfig {
  path: string;
  field: string;
  limit?: number;
  reverse?: boolean;
  prepend?: boolean;
}

@Injectable()
export class UserService {
  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions) { }

  getUser(id): Observable<User> {
    return this.afs
      .doc(`users/${id}`)
      .snapshotChanges()
      .pipe(
        distinctUntilChanged(),
        map((snap: any) => {
          return new UserClass({
            uid: snap.payload.id,
            ...snap.payload.data(),
          });
        })
      );
  }

  getUsersByIds(ids: string[]): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('uid', 'in', ids))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map((user: any) => {
            return new UserClass({
              id: user.payload.doc.id,
              ...user.payload.doc.data(),
            });
          });
        })
      );
  }

  getUsersByEmail(email: string[]): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('email', 'in', email))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map(
            (user: any) =>
              new UserClass({
                id: user.payload.doc.id,
                ...user.payload.doc.data(),
              })
          );
        })
      );
  }

  getAllUsers() {
    return this.afs
      .collection('users')
      .snapshotChanges()
      .pipe(
        distinctUntilChanged(),
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const uid = a.payload.doc.id;
            return new UserClass({ id: uid, ...data });
          })
        )
      );
  }

  createUser(data) {
    const { password, ...userData } = data;
    // Moved creating user to the server.
    const callable = this.fns.httpsCallable('userApi-createUser');
    return callable(data);
  }

  searchUserWithTerm(term) {
    const callable = this.fns.httpsCallable('userApi-searchUserByEmailOrName');
    return callable(term);
  }

  searchUserTypeAndTerm({ searchTerm }, type) {
    return this.afs
      .collection(`users`, (ref) =>
        ref
          .orderBy('name')
          .startAt(searchTerm)
          .endAt(searchTerm + '~')
          .where('appAccess', 'in', [type])
          .limit(30)
      )
      .snapshotChanges()
      .pipe(
        map((snap) => snap.map(
          (el: any) =>
            new UserClass({
              id: el.payload.doc.id,
              ...el.payload.doc.data(),
            })
        )
        ),
        tap(data => console.warn(data))
      );
  }

  updateUser(user: User) {
    const plainJSON = instanceToPlain(user, { exposeUnsetFields: false })
    return this.afs.doc(`users/${user.uid}`).update(plainJSON);
  }

  getUersByGroupId(groupId) {
    return this.afs
      .collection(`users`, (ref) => ref.where('group.id', '==', groupId))
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map(
            (el: any) =>
              new UserClass({
                id: el.payload.doc.id,
                ...el.payload.doc.data(),
              })
          )
        )
      );
  }

  getAdminUsers(): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('admin', '==', true))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map(
            (user: any) =>
            ({
              id: user.payload.doc.id,
              ...user.payload.doc.data(),
            } as User[])
          );
        })
      );
  }

  changeUserEmail(changeData: UpdateUserEmail) {
    const callable = this.fns.httpsCallable('authApi-changeUserEmail');
    return callable(changeData);
  }
}
