import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { UserService } from '@store/user-store/services/user.service';
import { of } from 'rxjs';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';
import * as AuthActions from '../actions/auth.actions';

@Injectable()
export class AuthEffects implements OnInitEffects {
  $loadUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadUser),
      exhaustMap(() => this.afAuth.authState),
      switchMap((user) => {
        if (user) {
          return this.userService.getUser(user.uid).pipe(
            switchMap((usr: any) => {
              return [
                AuthActions.authenticated({ user: usr }),
              ];
            })
          );
        } else {
          return [AuthActions.loadAuthsFailure({ error: 'No user found' })];
        }
      }),
      catchError((err: any) => {
        return of(err);
      })
    );
  });

  constructor(private actions$: Actions, private afAuth: AngularFireAuth, private userService: UserService) { }

  ngrxOnInitEffects(): Action {
    return AuthActions.loadUser();
  }
}
