import { Horse } from '@models/horse.model';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { filter, includes } from 'lodash';
import { horsesFeatureKey, selectAllHorsesAdapter, State } from '../reducers/horses.reducer';

export const selectHorsesState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(horsesFeatureKey);

export const isLoading = (state: State): boolean => state.loading;
export const isLoaded = (state: State): boolean => state.loaded;

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectHorsesState,
  isLoading
);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(selectHorsesState, isLoaded);

export const selectAllHorses: (state: object) => any[] = createSelector(
  selectHorsesState,
  selectAllHorsesAdapter
);

export const selectHorseWithOwner = (owner: string) =>
  createSelector(selectAllHorses, (horses: any[]) => {
    if (horses) {
      return horses.filter((horse) => horse.owner === owner);
    } else {
      return null;
    }
  });

export const selectHorsesAssociatedWithUser = (uid: string) =>
  createSelector(selectAllHorses, (horses: Horse[]) => {
    if (horses) {
      return horses.filter((obj: Horse) => {
        const rolesUser = Object.keys(obj?.roles);
        const roleIncludesUser = rolesUser.includes(uid);
        if (roleIncludesUser) {
          return obj;
        }
      });
    } else {
      return null;
    }
  });

export const selectHorsesWithHorseIds = (horseIds: string[]) =>
  createSelector(selectAllHorses, (horses: Horse[]) => {
    if (horses && horseIds) {
      const filteredHorsesByIds = filter(horses, (horse) => includes(horseIds, horse.id));
      return filteredHorsesByIds;
    } else {
      return null;
    }
  });

export const selectSingleHorse = (horseIds: string) =>
  createSelector(selectAllHorses, (horses: Horse[]) => {
    if (horses && horses.length !== 0) {
      return horses.find((horse) => horse.id === horseIds);
    } else {
      return null;
    }
  });

export const selectHorsesInGroup = (groupId: string) =>
  createSelector(selectAllHorses, (horses: Horse[]) => {
    if (horses) {
      return horses.filter((horse: Horse) => horse.group && horse.group.id === groupId);
    } else {
      return null;
    }
  });
