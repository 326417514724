import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CloneHorseData, Horse } from '@models/horse.model';
import { filterForUniqueInStringArray } from '@shared/helpers/unique.helper';
import { UserService } from '@store/user-store/services/user.service';
import { documentId } from 'firebase/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HorseService {
  constructor(
    private afs: AngularFirestore,
    private afn: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private userService: UserService
  ) { }

  getHorseByUid(uid?: string) {
    return this.afs
      .collection('horses', (ref) => ref.where('owner', '==', uid))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getConsumerHorseByUid(uid: string) {
    return this.afs
      .collection('horses', (ref) =>
        ref.where(`roles.${uid}`, 'in', ['horseOwner', 'vet', 'consumer', 'recorder', 'EHP'])
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getFirst20Horses() {
    return this.afs
      .collection('/horses', (ref) => ref.where('dbStatus', '==', 'active').limit(20))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getAllHorses() {
    return this.afs
      .collection('/horses', (ref) => ref.where('dbStatus', '==', 'active'))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getHorseByLegacyId(legacyId) {
    return this.afs
      .collection('horses', (ref) => ref.where('__id', '==', legacyId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getHorsesWithGroupId(groupId) {
    return this.afs
      .collection('horses', (ref) => ref.where('group.id', '==', groupId))
      .snapshotChanges()
      .pipe(
        map((snaps) =>
          snaps.map((h) => {
            const data = h.payload.doc.data() as any;
            const id = h.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getSingleHorse(hId) {
    return this.afs
      .doc(`/horses/${hId}`)
      .snapshotChanges()
      .pipe(
        map((horse: any) => {
          return {
            id: horse.payload.id,
            ...horse.payload.data(),
          };
        })
      );
  }

  getHorsesBasedOnIds(ids) {
    return this.afs
      .collection(`horses`, (ref) => ref.where(documentId(), 'in', ids))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }
  getRecordings(ids) {
    return this.afs.collection(`stride-analysis`);
  }

  searchHorseWithTerm(term) {
    return this.afs
      .collection(`horses`, (ref) =>
        ref
          .where('dbStatus', '==', 'active')
          .orderBy('name')
          .startAt(term)
          .endAt(term + '~')
          .limit(30)
      )
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((el: any) => ({
            id: el.payload.doc.id,
            ...el.payload.doc.data(),
          }))
        ),
        switchMap((horses: Horse[]) => {
          const uids = horses.map(horse => horse.owner) as string[];
          const uniqueUids = filterForUniqueInStringArray(uids) as string[];
          console.warn(uniqueUids);
          return combineLatest([
            of(horses),
            this.userService.getUsersByIds(uniqueUids)
          ]).pipe(
            map(([horses, users]) => {
              const withUserName = horses.map((horse: Horse) => {
                const userName = users.find((user) => user.id == horse.owner);
                return {
                  ...horse,
                  documentOwnerName: userName?.name || 'Unknown'
                }
              })

              return withUserName;
            })
          )
        }),
      );
  }

  createHorse(data) {
    const toSave = {
      ...data,
      numbers: {
        [data.owner]: {
          numAnalyzing: 0,
          numEvents: 0,
          numNewAnalyzedRecordings: 0,
          numPending: 0,
          numRecordings: 0,
          numUpdatedAnalysis: 0,
        },
      },
    };
    return this.afs.collection('horses').add(toSave);
  }

  horseClone(data: CloneHorseData) {
    const callable = this.afn.httpsCallable('horsesApi-cloneHorse');
    return callable(data);
  }
}
