import { IssueCategoryType } from '@models/types/issue.type';

export interface Issue {
  id?: string;
  title: string; // Previously description
  name: string;
  description: string;
  category: IssueCategoryType;
  isActive: boolean;
  warningMessage: IssueMessage;
  declineMessage: IssueMessage;
  resnetRegression: ResnetRegression | null;
  link: IssueLink | null;
  issueDescription: IssueDescription;
}

export interface IssueLink {
  path: string;
  title: string;
}

export interface IssueDescription {
  fatal: IssueMessage | null;
  severe: IssueMessage | null;
  medium: IssueMessage | null;
  mild: IssueMessage | null;
}

export class IssueDescriptionClass implements IssueDescription {
  fatal: IssueMessage;
  severe: IssueMessage;
  medium: IssueMessage;
  mild: IssueMessage;

  constructor(o: any = {}) {
    this.fatal = o['fatal'] ?? null;
    this.severe = o['severe'] ?? null;
    this.medium = o['medium'] ?? null;
    this.mild = o['mild'] ?? null;
  }
}

export interface IssueMessage {
  en: string; // english warning message
}

export interface ResnetRegression {
  decline?: ResnetIssue;
  warning?: ResnetIssue;
}

export interface ResnetIssue {
  category: string;
  name: string;
}
// Only used for internal purposes of passign data
export interface TranslationDeclineStructure {
  path: ResnetIssue;
  declineMessage: IssueMessage;
}

export class IssueClass implements Issue {
  id?: string;
  title: string;
  name: string;
  description: string;
  category: IssueCategoryType;
  isActive: boolean;
  warningMessage: IssueMessage;
  declineMessage: IssueMessage;
  resnetRegression: ResnetRegression;
  link: IssueLink;
  issueDescription: IssueDescription;

  constructor(o: any = {}) {
    this.id = o['id'] ?? null;
    this.title = o['title'] ?? null;
    this.name = o['name'] ?? null;
    this.description = o['description'] ?? null;
    this.category = o['category'] ?? null;
    this.isActive = o['isActive'] ?? null;
    this.warningMessage = o['warningMessage'] ?? null;
    this.declineMessage = o['declineMessage'] ?? null;
    this.resnetRegression = o['resnetRegression'] ?? null;
    this.link = o['link'] ?? null;
    this.issueDescription = o['issueDescription'] ?? new IssueDescriptionClass();
  }
}
