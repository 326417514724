import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class AnalgesiaService {

  constructor(private afs: AngularFirestore) { }

  createAnalgesiaTags() {

    const frontlimbs = {
      'Intra-articular': [
        {
          name: 'Coffin joint',
          abbreviation: 'Coffin',
        },
        {
          name: 'Pastern joint',
          abbreviation: 'Pastern',
        },
        {
          name: 'Fetlock joint',
          abbreviation: 'Fetlock',
        },
        {
          name: 'Digital flexor tendon sheath',
          abbreviation: 'DFTS',
        },
        {
          name: 'Intercarpal joint',
          abbreviation: 'IntCarp',
        },
        {
          name: 'Radiocarpal joint',
          abbreviation: 'RadCarp',
        },
        {
          name: 'Elbow joint',
          abbreviation: 'Elbow',
        },
        {
          name: 'Shoulder joint',
          abbreviation: 'Shoulder',
        },
        {
          name: 'Other',
          abbreviation: null,
        }
      ],
      regional: [
        {
          name: 'Palmar digital nerve',
          abbreviation: 'PDN',
        },
        {
          name: 'Abaxial sesamoid',
          abbreviation: 'Abaxial',
        },
        {
          name: 'Low 4 point',
          abbreviation: 'L4P',
        },
        {
          name: 'High 4 point',
          abbreviation: 'H4P',
        },
        {
          name: 'Lateral palmar nerve',
          abbreviation: 'LPN',
        },
        {
          name: 'Infiltration of the Proximal Suspensory Ligament',
          abbreviation: 'PSL',
        },
        {
          name: 'N. Ulnaris',
          abbreviation: 'Ulnar',
        },
        {
          name: 'N. Medianus',
          abbreviation: 'Median',
        },
        {
          name: 'Other',
          abbreviation: null,
        }
      ]
    }


    const hindLimbs = {
      'Intra-articular': [
        {
          name: 'Coffin joint',
          abbreviation: 'Coffin',
        },
        {
          name: 'Pastern joint',
          abbreviation: 'Pastern',
        },
        {
          name: 'Fetlock joint',
          abbreviation: 'Fetlock',
        },
        {
          name: 'Digital flexor tendon sheath',
          abbreviation: 'DFTS',
        },
        {
          name: 'Tarsometatarsal joint',
          abbreviation: 'TMT',
        },
        {
          name: 'Distal intertarsal joint',
          abbreviation: 'DIT',
        },
        {
          name: 'Tarsocrural joint',
          abbreviation: 'TC',
        },
        {
          name: 'Medial femorotibial joint',
          abbreviation: 'medFT',
        },
        {
          name: 'Lateral femorotibial joint',
          abbreviation: 'latFT',
        },
        {
          name: 'Femoropatellar joint',
          abbreviation: 'FP',
        },
        {
          name: 'Coxofemoral joint',
          abbreviation: 'CF',
        },
        {
          name: 'Sacroiliac joint',
          abbreviation: 'SI',
        },
        {
          name: 'Other',
          abbreviation: null,
        }
      ],
      regional: [
        {
          name: 'Palmar digital nerve',
          abbreviation: 'PDN',
        },
        {
          name: 'Abaxial sesamoid',
          abbreviation: 'Abaxial',
        },
        {
          name: 'Low 4 point',
          abbreviation: 'L4P',
        },
        {
          name: 'Low 6 point',
          abbreviation: 'L6P',
        },
        {
          name: 'High 6 point',
          abbreviation: 'H6P',
        },
        {
          name: 'Lateral palmar nerve',
          abbreviation: 'LPN',
        },
        {
          name: 'Infiltration of the Proximal Suspensory Ligament',
          abbreviation: 'PSL',
        },
        {
          name: 'N. Tibialis',
          abbreviation: 'Tibial',
        },
        {
          name: 'N. Fibularis',
          abbreviation: 'Fibular',
        },
        {
          name: 'Other',
          abbreviation: null,
        }
      ]
    }


    const hindLimbsIntra = this.generateLocationTags(hindLimbs['Intra-articular'], 'hind', 'Intra-articular')
    const hindLimbsReg = this.generateLocationTags(hindLimbs['regional'], 'hind', 'Regional')
    const frontLimbsIntra = this.generateLocationTags(frontlimbs['Intra-articular'], 'front', 'Intra-articular')
    const frontLimbsReg = this.generateLocationTags(frontlimbs['regional'], 'front', 'Regional')
    const axialSkeleton = this.generateTag({ name: 'Other', abbreviation: null }, 'axial', 'Axial-skeleton', 0)
    return [...hindLimbsIntra, ...hindLimbsReg, ...frontLimbsIntra, ...frontLimbsReg, axialSkeleton]

  }

  generateLocationTags(tags, body, type) {
    const generatedTags = []
    tags.map((tag, i) => {
      if (body === 'hind') {
        const lh = this.generateTag(tag, 'lh', type, i);
        const rh = this.generateTag(tag, 'rh', type, i);
        generatedTags.push(lh)
        generatedTags.push(rh)
      } else if (body === 'front') {
        const lf = this.generateTag(tag, 'lf', type, i);
        const rf = this.generateTag(tag, 'rf', type, i);
        generatedTags.push(lf)
        generatedTags.push(rf)
      }
    })

    return generatedTags;
  }

  generateTag(tag, location, type, index) {
    const generatedTag = {
      id: this.afs.createId(),
      location,
      type: type,
      name: tag.name,
      abbreviation: tag.abbreviation,
      isActive: true,
      dateAdded: new Date(),
      index
    }
    return generatedTag
  }


}
