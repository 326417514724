import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '@public/login/login.component';
import { AuthService } from '@store/auth-store/services/auth.service';
import { DialogData } from 'app/app.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  showPassword = false;

  loading$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;

  loginForm: FormGroup;

  /* getters for better html look */
  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      rememberMe: ['']
    });
  }

  ngOnInit() { }

  async login() {
    const loginServ = await this.authService.login(this.loginForm.value);
    if (loginServ) {
      this.dialogRef.close();
      this.router.navigate(['dashboard']);
    } else {
      alert('Oups - something went wrong');
    }
  }
}
