export interface StrideReasonItem {
  strideFrequencyOk: boolean;
  keypointScoreOk: boolean;
  gaitOk: boolean;
  limbsInStanceOk: boolean;
  isMainDirection: boolean;
}

export interface VariableReasonItem {
  keypointScoreOk: boolean;
  frequencyInlier: boolean;
  secondHarmonicPhaseOk: boolean;
  scaledAmplitudeInlier: boolean;
  totalAmplitudeInlier: boolean;
  curveShapeInlier: boolean;
  amplitude1Inlier: boolean;
  amplitude2Inlier: boolean;
  amplitude3Inlier: boolean;
  amplitude4Inlier: boolean;
  phase1Inlier: boolean;
  phase2Inlier: boolean;
  phase3Inlier: boolean;
  phase4Inlier: boolean;
  ratio1Inlier: boolean;
  ratio2Inlier: boolean;
  ratio3Inlier: boolean;
  ratio4Inlier: boolean;
  isVisible: boolean;
}

export interface StrideReasonsItem {
  body: string;
  strideIndex: number;
  strideReasons: StrideReasonItem[];
  variableReasons: VariableReasonItem[];
}

export interface StrideReasonsDetailItem {
  recordingId?: string;
  strideReasons: StrideReasonsItem[];
}

export class StrideReasonsDetailItemClass implements StrideReasonsDetailItem {
  recordingId?: string;
  strideReasons: StrideReasonsItem[];

  constructor(o: any = {}) {
    this.strideReasons = o['reasons'] ?? null;
    this.recordingId = o['recordingId'] ?? null;
  }
}
