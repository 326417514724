import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { UserGroupActions, UserGroupReducer, UserGroupSelctors } from '@store/user-group-store';
import { Group } from '@models/group.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserGroupStoreFacade {
  constructor(private readonly store: Store<UserGroupReducer.State>) {}

  currentUserGroupEntity$ = (userGroupId: string) =>
    this.store.pipe(select(UserGroupSelctors.selectSingleUserGroup(userGroupId)));

  isCurrentUserGroupEntityLoaded$ = (userGroupId: string) =>
    this.store.pipe(
      select(UserGroupSelctors.selectSingleUserGroup(userGroupId)),
      map((userGroup: Group) => userGroup !== null)
    );

  getCurrentUserGroupEntity$ = (userGroupId: string) => {
    return this.store.dispatch(UserGroupActions.loadUserGroupById({ userGroupId }));
  };

  currentUserGroupHorsesLoadedInfo$: Observable<boolean | null> = this.store.pipe(
    select(UserGroupSelctors.selectUserGroupHorseLoadStatusById)
  );
}
