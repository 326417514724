import { Action, createReducer, on } from '@ngrx/store';
import * as UserGroupActions from '../actions/user-group.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Group } from '@models/group.model';

export const userGroupFeatureKey = 'userGroup';

export interface State extends EntityState<Group> {
  loading: boolean;
  loaded: boolean;
  userGroupSaved: boolean;
  error: string | null;
  offset: any | null;
}

export function selectUserGroupId(ug: Group) {
  return ug.id;
}

export function sortByName(ug1: Group, ug2: Group) {
  return ug1.name.localeCompare(ug2.name);
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: selectUserGroupId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: true,
  userGroupSaved: false,
  error: null,
  offset: null,
});

export const userGroupReducer = createReducer(
  initialState,

  on(UserGroupActions.loadUserGroups, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserGroupActions.loadUserGroupsSuccess, (state, { data }) => {
    return adapter.upsertMany(data, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
  on(UserGroupActions.loadUserGroupsFailure, (state, { error }) => {
    return adapter.removeAll({
      ...state,
      loaded: true,
      loading: false,
      error: error?.message,
    });
  }),
  on(UserGroupActions.loadUserGroupById, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserGroupActions.loadUserGroupByIdSuccess, (state, { data }) => {
    return adapter.upsertOne(data, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
  on(UserGroupActions.loadUserGroupByIdFailure, (state, { error }) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: error?.message,
    };
  }),
  on(UserGroupActions.updateUserGroup, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserGroupActions.updateUserGroupSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      userGroupSaved: true,
    };
  }),
  on(UserGroupActions.addNewUserGroup, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserGroupActions.addNewUserGroupSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      userGroupSaved: true,
    };
  }),
  on(UserGroupActions.addNewUserGroupFailure, (state, { error }) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: error?.message,
    };
  }),
  on(UserGroupActions.removeUserFromUserGroup, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserGroupActions.removeUserFromUserGroupSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
    };
  }),
  on(UserGroupActions.removeUserFromUserGroupFailure, (state, { error }) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: error?.message,
    };
  }),
  on(UserGroupActions.resetUserGroupSavedSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      userGroupSaved: false,
    };
  }),
  on(UserGroupActions.updateUserGroupHorsesLoadStatus, (state, { updatedUserGroup }) => {
    return adapter.upsertOne(updatedUserGroup, {
      ...state,
      loaded: true,
      loading: false,
    });
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return userGroupReducer(state, action);
}

export const {
  selectIds: selectUserGroupIdsAdapter,
  selectEntities: selectUserGroupEntitiesAdapter,
  selectAll: selectAllUserGroupsAdapter,
  selectTotal: selectUserGroupTotalAdapter,
} = adapter.getSelectors();
