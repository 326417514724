import { createAction, props } from '@ngrx/store';

export const loadCommons = createAction(
  '[Common] Load Commons'
);

export const loadCommonsSuccess = createAction(
  '[Common] Load Commons Success',
  props<{ data: any }>()
);

export const loadCommonsFailure = createAction(
  '[Common] Load Commons Failure',
  props<{ error: any }>()
);

export const addCommons = createAction(
  '[Common] Add Commons',
  props<{ commons: any[] }>()
)