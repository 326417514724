import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AnalysisResults } from '../../../../../models/analysis/analysis-results.model';
import * as AnalysisActions from '../actions/analysis.actions';
import * as AnalysisReducer from '../reducers/analysis.reducer';
import * as AnalysisSelectors from '../selectors/analysis.selectors';

@Injectable()
export class AnalysiStoreFacade {
  constructor(private readonly store: Store<AnalysisReducer.State>) {}

  currentAnalysisEntity$: Observable<AnalysisResults | null> = this.store.pipe(
    select(AnalysisSelectors.getCurrentAnalysisEntity)
  );

  currentAnalysisEntityLoaded$: Observable<boolean> = this.store.pipe(
    select(AnalysisSelectors.getCurrentAnalysisEntity),
    map((analysis: AnalysisResults) => analysis !== null)
  );

  setCurrentAnalysisEntity(analysis: AnalysisResults) {
    return this.store.dispatch(AnalysisActions.addSingleAnalysis({ analysis: analysis }));
  }
}
