import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { authState } from '../../auth-store';
import { AnalysisResults } from '@models/analysis/analysis-results.model';
import { Observable, combineLatest } from 'rxjs';

@Injectable()
export class AnalysisResultsService {
  constructor(private afs: AngularFirestore, private store: Store<authState.State>) { }

  loadSingleAnalysisById(id): Observable<AnalysisResults> {
    const analysisResultRef = this.afs.doc(`analysisResults/${id}`);
    const stridesSubCollectionRef = analysisResultRef.collection('strides');

    const analysisResultDocData$ = analysisResultRef.snapshotChanges();
    const stridesSubCollectionData$ = stridesSubCollectionRef.snapshotChanges();

    const combinedData$: Observable<any> = combineLatest([
      analysisResultDocData$,
      stridesSubCollectionData$,
    ]).pipe(
      distinctUntilChanged(),
      map(([analysisResultSnap, stridesSnap]) => {
        const strides = stridesSnap.reduce(
          (results, u) => ((results[u.payload.doc.id] = u.payload.doc.data()), results),
          {}
        );
        return {
          id: analysisResultSnap.payload.id,
          ...(analysisResultSnap.payload.data() as AnalysisResults),
          strides: strides,
        };
      })
    );

    return combinedData$;
  }
}
