import { Horse } from '@models/horse.model';
import { createAction, props } from '@ngrx/store';

export const loadHorses = createAction('[Horses / API] Load Horses', props<{ uid: string }>());

export const loadConsumerHorses = createAction(
  '[Horses / API] Load Consumer Horses',
  props<{ uid: string }>()
);

export const loadSinglehorse = createAction(
  '[Horses / API] Load Single Horsess',
  props<{ horseId: string }>()
);

export const loadAdminHorses = createAction('[Horses / API ] Load horses as admin');

export const loadHorsesByIds = createAction(
  '[Horses / API] Load Horses by horse Ids',
  props<{ horseIds: string[] }>()
);

export const addHorses = createAction('[Horses / Store] Add Horses', props<{ horses: Horse[] }>());

export const addSingleHorse = createAction('[Horses / Store] Add single horse', props<{ horse: Horse }>());

export const loadHorsesAssociatedUser = createAction(
  '[Horses / API] Load User Associated Horsess',
  props<{ uid: string }>()
);
// Update - Handle updates here :)

// * WHY *//
export const loadHorsessSuccess = createAction('[Horses] Load Horsess Success', props<{ data: any }>());

export const loadHorsessFailure = createAction('[Horses] Load Horsess Failure', props<{ error: any }>());

export const loadSharedHorses = createAction(
  '[Horses / API] Load shared horses',
  props<{ groupId: string }>()
);
