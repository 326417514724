import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromUserGroup from './reducers/user-group.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserGroupEffects } from './effects/user-group.effects';
import { GroupService } from './services/group.service';
import { UserGroupStoreFacade } from './facades/user-group-store.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserGroup.userGroupFeatureKey, fromUserGroup.reducer),
    EffectsModule.forFeature([UserGroupEffects]),
  ],
  providers: [GroupService, UserGroupStoreFacade],
})
export class UserGroupStoreModule {}
