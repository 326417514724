<div class="owner-input-wrapper">
  <ng-container *ngIf="autoComplete; else basicSelect">
    <div class="form-field">
      <div class="form-field__control">
        <input type="text" class="form-field__input" placeholder=" " name="label" id="label" [matAutocomplete]="auto"
          [formControl]="control" />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
            <span class="select-item">
              <b>{{ option.name }}</b></span>
          </mat-option>
        </mat-autocomplete>
        <label for="label" class="form-field__label">{{ label }}</label>
        <!-- Basic select -->
      </div>
    </div>
  </ng-container>
  <ng-template #basicSelect>
    <mat-form-field>
      <mat-label> App Version</mat-label>
      <mat-select [formControl]="control">
        <mat-option *ngFor="let option of options" [value]="option.value">
          <b>{{ option.name }}</b>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</div>