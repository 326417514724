import { StrideDataPoints } from '@models/stride-datapoints.model';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AnalysisResults } from '@models/analysis/analysis-results.model';
import { adapter, analysisFeatureKey, State } from '../reducers/analysis.reducer';
import * as fromRouterSelector from '../../router-store/router.selectors';

export const selectAnalysisState = createFeatureSelector<State>(analysisFeatureKey);

export const isLoading = (state: State): boolean => state.loading;
export const isLoaded = (state: State): boolean => state.loaded;
export const activeStride = (state: State): number => state.activeStride;

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAnalysisState,
  isLoading
);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectAnalysisState,
  isLoaded
);

export const selectActiveStride = createSelector(selectAnalysisState, activeStride);

export const selectAllAnalysis: (state: object) => AnalysisResults[] =
  adapter.getSelectors(selectAnalysisState).selectAll;

export const selectSingleAnalysis = (id: string) =>
  createSelector(selectAllAnalysis, (analysis: AnalysisResults[]) => {
    if (analysis) {
      return analysis.find((a) => a.id === id);
    } else {
      return null;
    }
  });

export const getCurrentAnalysisEntity = createSelector(
  selectAnalysisState,
  fromRouterSelector.selectRouteNestedParam('recordingId'),
  (state: State, recordingId: string) => {
    return recordingId && state.entities[recordingId] ? state.entities[recordingId] : null;
  }
);
