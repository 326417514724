<div class="owner-input-wrapper">
  <div class="form-field">
    <div class="form-field__control">
      <input type="text" class="form-field__input" placeholder=" " name="owner" id="owner" [matAutocomplete]="auto"
        [formControl]="searchTerm" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <!-- Preperation for loading state -->
        <!-- <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading"> -->
        <mat-option *ngFor="let user of users$ | async" [value]="user">
          <span class="select-item">
            <b>{{ user.name }}</b> <span class="tiny"> {{user?.email}}
            </span> </span>
        </mat-option>
        <!-- </ng-container> -->
      </mat-autocomplete>
      <label for="owner" class="form-field__label">Select Owner</label>
    </div>
  </div>
</div>