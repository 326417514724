import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserSelctors, UserState } from '@store/user-store';

@Injectable()
export class UserStoreFacade {
  constructor(private readonly store: Store<UserState.State>) {}

  currentUserHorsesLoadedInfo$: Observable<boolean | null> = this.store.pipe(
    select(UserSelctors.selectUserHorseLoadStatusById)
  );
}
