import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromStrideReviewReducer from '../reducers/stride-review-reducer';
import { StrideReasonsDetailItem } from '../../../../../models/analysis/stride-reasons.model';

export const strideReasonsDetailItemAdapter: EntityAdapter<StrideReasonsDetailItem> =
createEntityAdapter<StrideReasonsDetailItem>({
  selectId: selectStrideReasonsDetailItemId,
});

export function selectStrideReasonsDetailItemId(strideReasonsDetailItem: StrideReasonsDetailItem) {
  return strideReasonsDetailItem.recordingId;
}

export const selectStrideReasonsDetailItemState = (state: fromStrideReviewReducer.State) => state.reasons;

export const { selectAll: selectAllStrideReasonsDetailItems } = strideReasonsDetailItemAdapter.getSelectors();

export interface StrideReasonsDetailItemState extends EntityState<StrideReasonsDetailItem> {}
