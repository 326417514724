import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

/** Both emails should match */
export const MatchingEmailValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const email = control.get('email');
  const emailConfirm = control.get('emailConfirm');

  return email && emailConfirm && email.value !== emailConfirm.value
    ? { matchingEmail: true }
    : null;
};

@Directive({
  selector: '[appMatchingEmail]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MatchingEmailDirective,
      multi: true
    }
  ]
})
export class MatchingEmailDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return MatchingEmailValidator(control);
  }

}
