import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromIssueStore from './reducers/issue-store.reducer';
import { EffectsModule } from '@ngrx/effects';
import { IssueStoreEffects } from './effects/issue-store.effects';
import { IssueService } from '@store/issue-store/services/issue.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromIssueStore.issueStoreFeatureKey, fromIssueStore.reducer),
    EffectsModule.forFeature([IssueStoreEffects]),
  ],
  providers: [IssueService],
})
export class IssueStoreModule {}
