import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromStrideReviewReducer from '../reducers/stride-review-reducer';
import { StrideFilteredKeyPointsItem } from '@models/analysis/stride-filtered-key-points.model';

export function selectStrideFilteredKeyPointsItemId(strideFilteredKeyPointItem: StrideFilteredKeyPointsItem) {
  return strideFilteredKeyPointItem.recordingId;
}

export const strideFilteredKeyPointsItemAdapter: EntityAdapter<StrideFilteredKeyPointsItem> =
  createEntityAdapter<StrideFilteredKeyPointsItem>({
    selectId: selectStrideFilteredKeyPointsItemId,
  });

export const selectFilteredKeyPointsState = (state: fromStrideReviewReducer.State) => state.filteredKeypoints;
export const { selectAll: selectAllStrideFilteredKeyPointsItems } =
  strideFilteredKeyPointsItemAdapter.getSelectors();

export interface StideFilteredKeyPointsItemState extends EntityState<StrideFilteredKeyPointsItem> {}
