import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAnalysis from './analysis-store/reducers/analysis.reducer';
import * as fromAuth from './auth-store/reducers/auth.reducer';
import * as fromHorses from './horses-store/reducers/horses.reducer';
import * as fromUser from './user-store/reducers/user.reducer';

// import * as fromRouter from '@ngrx/router-store';

export interface State {
  horses: fromHorses.State;
  [fromUser.userFeatureKey]: fromUser.State;
  analysis: fromAnalysis.State;
  auth: fromAuth.State;
  router: RouterReducerState<any>;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  horses: fromHorses.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
  analysis: fromAnalysis.reducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
