<div class="user-input-wrapper">
  <div class="form-field">
    <div class="form-field__control">
      <input type="text" class="form-field__input" placeholder=" " name="label" id="label" [matAutocomplete]="auto"
        [formControl]="searchTerm" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <ng-container *ngFor="let horse of horses$ | async">
          <mat-option *ngIf="horseId !== horse.id" [value]="horse">
            <span class="select-horse-item">
              <img [src]="horse.image" alt="" />
              <span class="text-area">
                <b>{{ horse.name }}</b> - {{horse.horseOwnerName}} <br>
                <span><b>Owner</b> {{horse?.documentOwnerName}}</span>
              </span>
            </span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <label for="label" class="form-field__label">Horse name</label>
    </div>
  </div>
</div>