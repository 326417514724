export const environment = {
  production: false,
  hmr: false,
  env: 'dev',
  firebase: {
    apiKey: 'AIzaSyCJM6fFXBNRLhunlTMoBkp_0pFMkG0NAqo',
    authDomain: 'sleip-dev.firebaseapp.com',
    databaseURL: 'https://sleip-dev.firebaseio.com',
    projectId: 'sleip-dev',
    storageBucket: 'sleip-dev.appspot.com',
    messagingSenderId: '616706935186',
    appId: '1:616706935186:web:ba3552ff42ecd03efd994b',
    measurementId: 'G-2YK3QTEVRZ',
  },
  algolia: {
    appId: 'D51WX9518Y',
    searchApiKey: '12c8d75fa34801cf7253424f72a00acf'
  },
};
