import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Issue } from '../../../../../models/commons/issues/issue.model';
import * as IssueStoreActions from '../actions/issue-store.actions';

export const issueStoreFeatureKey = 'issueStore';

export interface State extends EntityState<Issue> {
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

export function selectIssueId(i: Issue) {
  return i.id;
}

export function sortByName(a: Issue, b: Issue) {
  return a.name.localeCompare(b.name);
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectIssueId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(IssueStoreActions.loadIssueStores, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(IssueStoreActions.loadIssueStoresSuccess, (state, { data }) =>
    adapter.upsertMany(data, {
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(IssueStoreActions.loadIssueStoresFailure, (state, { error }) => {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: error.message,
    };
  })
);
