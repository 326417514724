import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStrideReview from '../reducers/stride-review-reducer';
import {
  selectAllStrideFilteredKeyPointsItems,
  selectFilteredKeyPointsState,
} from '../reducers/stride-filtered-key-point.entity';
import {
  selectAllStrideReasonsDetailItems,
  selectStrideReasonsDetailItemState,
} from '../reducers/stride-reason.entity';
import { StrideFilteredKeyPointsItem } from '@models/analysis/stride-filtered-key-points.model';
import { Observable, of } from 'rxjs';
import { StrideReasonsDetailItem } from '@models/analysis/stride-reasons.model';

export const selectStrideReviewModule = createFeatureSelector<fromStrideReview.State>(
  fromStrideReview.STRIDE_REVIEW_KEY
);

export const isLoading = (state: fromStrideReview.State): boolean => state.loading;
export const isLoaded = (state: fromStrideReview.State): boolean => state.loaded;

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectStrideReviewModule,
  isLoading
);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectStrideReviewModule,
  isLoaded
);

export const strideFilteredKeyPointsItemState = createSelector(
  selectStrideReviewModule,
  selectFilteredKeyPointsState
);
export const allStideFilteredKeyPointsItems = createSelector(
  strideFilteredKeyPointsItemState,
  selectAllStrideFilteredKeyPointsItems
);

export const selectSingleStideFilteredKeyPointsItem = (
  recordingId: string
): Observable<StrideFilteredKeyPointsItem> | Observable<any> | any => {
  return createSelector(
    allStideFilteredKeyPointsItems,
    (StrideFilteredKeyPointsItems: StrideFilteredKeyPointsItem[]) => {
      if (StrideFilteredKeyPointsItems) {
        return StrideFilteredKeyPointsItems.find((item) => item.recordingId === recordingId);
      } else {
        return of(false);
      }
    }
  );
};

export const strideReasonsDetailItemState = createSelector(
  selectStrideReviewModule,
  selectStrideReasonsDetailItemState
);
export const allStrideReasonsDetailItems = createSelector(
  strideReasonsDetailItemState,
  selectAllStrideReasonsDetailItems
);

export const selectSingleStideReasonDetailItem = (
  recordingId: string
): Observable<StrideReasonsDetailItem> | Observable<any> | any => {
  return createSelector(allStrideReasonsDetailItems, (StideReasonDetailItems: StrideReasonsDetailItem[]) => {
    if (StideReasonDetailItems) {
      return StideReasonDetailItems.find((item) => item.recordingId === recordingId);
    } else {
      return of(false);
    }
  });
};
