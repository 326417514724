import { User } from '@models/user.model';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { selectAllUsersReducer, State, userFeatureKey } from '../reducers/user.reducer';
import * as fromRouterSelector from '@store/router-store/router.selectors';

export const selectUserState = createFeatureSelector<State>(userFeatureKey);

export const isLoading = (state: State): boolean => state.loading;
export const isLoaded = (state: State): boolean => state.loaded;

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, isLoading);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(selectUserState, isLoaded);

export const selectAllUsers = createSelector(selectUserState, selectAllUsersReducer);

export const selectSingleUser = (uid: string) =>
  createSelector(selectAllUsers, (users: User[]) => {
    if (users) {
      return users.find((user: User) => user.uid === uid);
    } else {
      return null;
    }
  });

export const selectGroupUsers = (groupId: string, appAccess: string) =>
  createSelector(selectAllUsers, (users: User[]) => {
    if (users) {
      return users.filter((user) => user.group && user.group.id === groupId && user.appAccess === appAccess);
    } else {
      return null;
    }
  });

export const selectFilterUser = (sort: string, direction: string, page: number, pageSize: number) =>
  createSelector(selectAllUsers, (users: User[]) => {
    if (users) {
      const sorted = users.sort((a, b) => {
        if (sort === 'name') {
          if (direction === 'asc') {
            return b.name.localeCompare(a.name);
          } else {
            return a.name.localeCompare(b.name);
          }
        } else if (sort === 'userStatus') {
          if (direction === 'asc') {
            return b.userStatus.localeCompare(a.userStatus);
          } else {
            return a.userStatus.localeCompare(b.userStatus);
          }
        } else if (sort === 'lastEvent') {
          if (b?.lastEvent) {
            if (direction === 'asc') {
              return b.lastEvent.localeCompare(a.lastEvent);
            } else {
              return a.userStatus.localeCompare(b.userStatus);
            }
          } else {
            return a;
          }
        } else if (sort === 'userGroup') {
          if (a?.group) {
            if (direction === 'asc') {
              return b?.group?.name.localeCompare(a?.group?.name);
            } else {
              return a?.group?.name.localeCompare(b?.group?.name);
            }
          } else {
            return a;
          }
        } else if (sort === 'lastSession') {
          if (direction === 'asc') {
            return a.lastSession?.seconds.toString().localeCompare(b?.lastSession?.seconds.toString());
          } else {
            return b.lastSession?.seconds.toString().localeCompare(a?.lastSession?.seconds.toString());
          }
        }
        return null;
      });
      // TODO: Implement paging.
      sorted.slice(page * pageSize, (page + 1) * pageSize);
      return sorted;
    }
    return null;
  });

export const selectUsersByIds = (ids: string[]) =>
  createSelector(selectAllUsers, (users: User[]) => {
    if (users) {
      return users.filter(({ uid }) => ids.indexOf(uid) != -1);
    } else {
      return;
    }
  });

export const selectUserHorseLoadStatusById = createSelector(
  selectAllUsers,
  fromRouterSelector.selectRouteNestedParam('userId'),
  (users: User[], userId: string) => {
    if (users && userId) {
      return users.find((user: User) => user.uid === userId)?.horsesLoadStatus ?? false;
    } else {
      return false;
    }
  }
);
