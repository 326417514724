import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { GroupService } from '@store/user-group-store/services/group.service';
import { defer, of } from 'rxjs';
import { SubscriptionService } from '@store/common-store/services/subscription.service';
import * as UserGroupActions from '../actions/user-group.actions';

@Injectable()
export class UserGroupEffects {
  loadUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupActions.loadUserGroups),
      map((action) => action),
      mergeMap(({ offset, limit }) => {
        return this.groupService.getPagedGroups(offset, limit).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((data) => UserGroupActions.loadUserGroupsSuccess({ data })),
          catchError((error) => of(UserGroupActions.loadUserGroupsFailure({ error })))
        );
      })
    );
  });

  loadUserGroupById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupActions.loadUserGroupById),
      map((action) => action.userGroupId),
      mergeMap((userGroupId) => {
        return this.groupService.getGroup(userGroupId).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((data) => UserGroupActions.loadUserGroupByIdSuccess({ data })),
          catchError((error) => of(UserGroupActions.loadUserGroupByIdFailure({ error })))
        );
      })
    );
  });

  updateUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupActions.updateUserGroup),
      switchMap(({ userGroupForm }) =>
        defer(() => this.groupService.updateGroup(userGroupForm)).pipe(
          switchMap((response) => {
            return [UserGroupActions.updateUserGroupSuccess(), UserGroupActions.resetUserGroupSavedSuccess()];
          }),
          catchError((error) => of(UserGroupActions.updateUserGroupFailure({ error })))
        )
      )
    );
  });

  addNewUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupActions.addNewUserGroup),
      switchMap(({ userGroupForm }) =>
        defer(() => this.groupService.createGroup(userGroupForm)).pipe(
          switchMap(() => {
            return [UserGroupActions.updateUserGroupSuccess(), UserGroupActions.resetUserGroupSavedSuccess()];
          }),
          catchError((error) => of(UserGroupActions.addNewUserGroupFailure({ error })))
        )
      )
    );
  });

  removeUserFromUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserGroupActions.removeUserFromUserGroup),
      map((action) => action),
      mergeMap(({ userGroupId, userToRemove }) => {
        return of(
          this.groupService
            .removeUserFromGroup(userGroupId, userToRemove)
            .catch((error) => UserGroupActions.removeUserFromUserGroupFailure({ error }))
        );
      }),
      map(() => UserGroupActions.removeUserFromUserGroupSuccess())
    );
  });

  constructor(
    private actions$: Actions,
    private groupService: GroupService,
    private subService: SubscriptionService
  ) {}
}
