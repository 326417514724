import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { map, exhaustMap } from 'rxjs/operators';

import * as CommonActions from '../actions/common.actions';
import { CommonService } from '@store/common-store/services/common.service';
import { Action } from '@ngrx/store';



@Injectable()
export class CommonEffects implements OnInitEffects {

  loadCommons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommonActions.loadCommons),
      exhaustMap(() => {
        return this.commonService.getCommons().pipe(
          map((commons: any[]) => {
            return CommonActions.addCommons({ commons });
          })
        );
      })
    );
  });



  constructor(private actions$: Actions, private commonService: CommonService) { }


  ngrxOnInitEffects(): Action {
    return CommonActions.loadCommons();
  }
}
