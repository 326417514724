import { Injectable, OnDestroy } from '@angular/core';
import { SubscriptionService } from '@store/common-store/services/subscription.service';
import { Subject } from 'rxjs';

@Injectable()
export class DestroyService extends Subject<void> implements OnDestroy {
  constructor(private subService: SubscriptionService) {
    super();
  }

  ngOnDestroy(): void {
    this.next();
    this.complete();
    this.subService.unsubscribeNotifySubject$.next();
    this.subService.unsubscribeNotifySubject$.complete();
  }
}
