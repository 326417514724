import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCommon from './reducers/common.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CommonEffects } from './effects/common.effects';
import { CommonService } from '@store/common-store/services/common.service';
import { DestroyService } from '@store/common-store/services/destroy.service';
import { SubscriptionService } from '@store/common-store/services/subscription.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCommon.commonFeatureKey, fromCommon.reducer),
    EffectsModule.forFeature([CommonEffects]),
  ],
  providers: [CommonService, DestroyService, SubscriptionService],
})
export class CommonStoreModule {}
