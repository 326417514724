import { GroupType, GroupUserType } from '@models/types/group.type';
import { FirebaseTimings } from './firebaseTimings.model';

export interface Group {
  id?: string;
  createdAt: FirebaseTimings;
  name: string;
  names: string[];
  type: GroupType;
  users?: GroupUsers[];
  firestoreId?: string;
  shareHorsesInGroup?: boolean;
  groupShareRights?: boolean;
  previousUsers?: GroupUsers[];
  contractedUsers?: number;
  recorderUsers?: number;
  groupHorsesLoaded?: boolean;
  userAccess: UserAccess | null;
}

export interface UserAccess {
  [key: string]: string;
}
export interface GroupUsers {
  id: string;
  type: GroupUserType;
  autoShare?: boolean;
}

export class GroupClass implements Group {
  id?: string;
  createdAt: FirebaseTimings;
  name: string;
  names: string[];
  type: GroupType;
  users?: GroupUsers[];
  firestoreId?: string;
  shareHorsesInGroup?: boolean;
  groupShareRights?: boolean;
  previousUsers?: GroupUsers[];
  contractedUsers?: number;
  recorderUsers?: number;
  groupHorsesLoaded?: boolean;
  userAccess: UserAccess;

  constructor(o: any = {}) {
    this.id = o['id'] ?? null;
    this.createdAt = o['createdAt'] ?? null;
    this.name = o['name'] ?? null;
    this.names = o['names'] ?? null;
    this.type = o['type'] ?? null;
    this.users = o['users'] ?? null;
    this.firestoreId = o['firestoreId'] ?? null;
    this.shareHorsesInGroup = o['shareHorsesInGroup'] ?? null;
    this.groupShareRights = o['groupShareRights'] ?? null;
    this.previousUsers = o['previousUsers'] ?? null;
    this.contractedUsers = o['contractedUsers'] ?? null;
    this.recorderUsers = o['recorderUsers'] ?? null;
    this.groupHorsesLoaded = o['groupHorsesLoaded'] ?? false;
    this.userAccess = o['userAccess'] ?? null;
  }
}
