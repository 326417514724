import { createAction, props } from '@ngrx/store';

export const loadTags = createAction(
  '[Tag] Load Tags'
);

export const loadTagsSuccess = createAction(
  '[Tag] Load Tags Success',
  props<{ data: any }>()
);

export const loadTagsFailure = createAction(
  '[Tag] Load Tags Failure',
  props<{ error: any }>()
);
