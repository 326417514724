import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthStoreModule } from '@store/auth-store/auth-store.module';
import { HorsesStoreModule } from '@store/horses-store/horses-store.module';
import { UserStoreModule } from '@store/user-store/user-store.module';
import { UserGroupStoreModule } from '@store/user-group-store/user-group-store.module';
import { CommonStoreModule } from '@store/common-store/common-store.module';
import { IssueStoreModule } from '@store/issue-store/issue-store.module';
import { TagStoreModule } from '@store/tag-store/tag-store.module';
import { AnalysisStoreModule } from '@store/analysis-store/analysis-store.module';

@NgModule({
  declarations: [],
  imports: [
    AuthStoreModule,
    HorsesStoreModule,
    UserStoreModule,
    UserGroupStoreModule,
    CommonStoreModule,
    IssueStoreModule,
    TagStoreModule,
    AnalysisStoreModule,
    CommonModule,
  ],
})
export class RootStoreModule { }
