import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { HorseActions } from '../';
import { Horse } from '@models/horse.model';
import { HorseService } from '@store/horses-store/services/horse.service';
import * as HorsesActions from '../actions/horses.actions';
import { UserService } from '@store/user-store/services/user.service';
import { UserActions } from '@store/user-store';
import { User } from '@models/user.model';

@Injectable()
export class HorsesEffects {
  loadHorses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorsesActions.loadHorses),
      switchMap(({ uid }) => {
        const ownerStream = this.horseService.getHorseByUid(uid);
        return ownerStream.pipe(
          map((horses) => {
            return HorsesActions.addHorses({ horses });
          })
        );
      })
    );
  });

  loadConsumerHorses = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadConsumerHorses),
      switchMap(({ uid }) => {
        const consumerStream = this.horseService.getConsumerHorseByUid(uid);
        return consumerStream.pipe(
          map((horses) => {
            return HorseActions.addHorses({ horses });
          })
        );
      })
    );
  });

  loadSingleHorseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadSinglehorse),
      mergeMap(({ horseId: id }) => {
        const singleHorse = this.horseService.getSingleHorse(id);
        return singleHorse.pipe(
          map((horse) => {
            return HorseActions.addSingleHorse({ horse });
          })
        );
      })
    );
  });

  loadAdminHorses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorsesActions.loadAdminHorses),
      exhaustMap(() => {
        return this.horseService.getAllHorses().pipe(
          map((horses) => {
            return HorsesActions.addHorses({ horses });
          })
        );
      })
    );
  });

  loadSharedHorses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadSharedHorses),
      mergeMap(({ groupId }) => {
        return this.horseService.getHorsesWithGroupId(groupId).pipe(
          mergeMap((horses: Horse[]) => {
            return this.userService.getUersByGroupId(groupId).pipe(
              take(2),
              switchMap((users: User[]) => {
                return [HorseActions.addHorses({ horses }), UserActions.addUsers({ users })];
              })
            );
          })
        );
      })
    );
  });

  loadHorsesAssociatedWithUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadHorsesAssociatedUser),
      exhaustMap(({ uid }) => {
        return this.horseService.getConsumerHorseByUid(uid).pipe(
          map((horses: Horse[]) => {
            return HorseActions.addHorses({ horses });
          })
        );
      })
    );
  });

  loadHorsesByHorseIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadHorsesByIds),
      map((action) => action),
      mergeMap(({ horseIds }) => {
        return this.horseService.getHorsesBasedOnIds(horseIds).pipe(
          map((horses: Horse[]) => {
            return HorseActions.addHorses({ horses });
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private horseService: HorseService,
    private userService: UserService
  ) {}
}
